import currency, { type Options as CurrencyOptions } from "currency.js";
import { DateTime } from "luxon";

import type { Multilingual } from "./models";

export const ChfCurrencyOptions: CurrencyOptions = {
	symbol: "CHF ",
	separator: " ",
	increment: .05,
};

export function formatCurrency(amount: string | number | null | undefined): string {
	if (amount == null
		|| (typeof amount === "number" && amount === 0)
		|| (typeof amount === "string" && parseFloat(amount) === 0)) {
		return "–";
	}
	return currency(amount, ChfCurrencyOptions).format();
}

export function groupBy<T, K extends keyof any>(list: T[], getKey: (item: T) => K): Record<K, T[]> {
	return list.reduce((previous, currentItem) => {
		const group = getKey(currentItem);
		if (!previous[group]) previous[group] = [];
		previous[group].push(currentItem);
		return previous;
	}, {} as Record<K, T[]>);
}

export function pascalCase(value: string): string {
	return value.slice(0, 1).toUpperCase() + value.slice(1);
}

export enum State {
	Loading = "loading",
	Normal = "normal",
	Finished = "finished",
	Error = "error"
}

export function getLocaleValue(value: Multilingual | null | undefined): string {
	if (value == null) {
		return "";
	}
	return value[document.documentElement.lang]
		?? value["iv"]
		?? "";
}

export function stripRegionFromIsoDate(value: string): string {
	return value.split(" ")[0];
}

export function fromZonedDateTime(value: string): DateTime {
	return DateTime.fromISO(stripRegionFromIsoDate(value));
}

export function isNullOrEmpty(value: string | null | undefined): value is "" | null | undefined {
	return value === null || value === undefined || value === "";
}
