import { eventBus } from "./event-bus";

export const ShortcutNavigationClickedEvent = "ShortcutNavigationClicked";
export interface ShortcutNavigationClickedEventArgs {
	id: string;
}

export class Navigation {
	constructor() {
		let toggle = document.getElementById("mainNavToggle");
		toggle?.addEventListener("click", e => {
			document.body.classList.toggle("is-nav-open");
			toggle?.classList.toggle("is-active");
		});

		let shortcutNav = document.getElementById("shortcutNav");
		if (shortcutNav != null) {
			shortcutNav.addEventListener("click", e => {
				let target = e.target as HTMLElement;
				let nodeId = target?.dataset["nodeid"];
				if (nodeId != null) {
					let args: ShortcutNavigationClickedEventArgs = {
						id: nodeId
					};
					eventBus.emit(ShortcutNavigationClickedEvent, args);
				}
			});

			//let observer = new IntersectionObserver((entries, observer) => {
			//	entries.forEach(entry => {
			//		if (entry.isIntersecting) {
			//			shortcutNav?.classList.add("is-hidden");
			//		} else {
			//			shortcutNav?.classList.remove("is-hidden");
			//		}
			//	});
			//}, {

			//});
			//observer.observe(shortcutNav);
		}
	}
}
