export class Offer {
	constructor() {
		let bookingContainer = document.querySelector(".offer-booking");
		let headerTickets = document.querySelector(".offer-tickets__teaser, .header-tickets__wrapper");
		if (bookingContainer == null || headerTickets == null) {
			return;
		}

		let observer = new IntersectionObserver((entries, observer) => {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					headerTickets?.classList.add("is-hidden");
				} else {
					headerTickets?.classList.remove("is-hidden");
				}
			});
		}, {
			rootMargin: "-30%",
			threshold: 0
		});
		observer.observe(bookingContainer);
	}
}
