import { Vue, Component, Prop, Watch } from "@wagich/vue-facing-decorator-metadata";

import { eventBus } from "../event-bus";
import { ShortcutNavigationClickedEvent, type ShortcutNavigationClickedEventArgs } from "../navigation";

import { render } from "./main-navigation.html";

interface NavigationTab {
	id: string;
	title: string;
}

const ActiveTabKey = "MainNavigation__ActiveTab";
const IsTabletMediaQuery = window.matchMedia("(min-width: 769px)");

@Component({
	render
})
export class MainNavigation extends Vue {
	activeTab: number = 0;
	activePanel: number | null = null;
	toggle: HTMLElement | null;
	tabs: NavigationTab[] = [];
	isTablet: boolean = false;

	@Prop() selector: string;

	@Watch("activeTab")
	onActiveTabChanged() {
		try {
			window.sessionStorage.setItem(ActiveTabKey, this.activeTab.toString());
		} catch { }
	}

	created() {
		let dataElement = document.querySelector<HTMLScriptElement>(this.selector);
		if (dataElement?.tagName === "SCRIPT" && dataElement.type === "application/json") {
			this.tabs = JSON.parse(dataElement.innerHTML);
		}

		this.toggle = document.getElementById("mainNavToggle");
		eventBus.on(ShortcutNavigationClickedEvent, this.onShortcutNavigationClickedBound);
	}

	mounted() {
		IsTabletMediaQuery.addEventListener("change", e => {
			this.isTablet = IsTabletMediaQuery.matches;
		});
		this.isTablet = IsTabletMediaQuery.matches;

		try {
			this.activeTab = parseInt(window.sessionStorage.getItem(ActiveTabKey) || "0");
		} catch { }
	}

	beforeDestroy() {
		eventBus.off(ShortcutNavigationClickedEvent, this.onShortcutNavigationClickedBound);
	}

	onShortcutNavigationClickedBound = this.onShortcutNavigationClicked.bind(this);
	onShortcutNavigationClicked(args: ShortcutNavigationClickedEventArgs) {
		let index = this.tabs.findIndex(t => t.id === args.id);
		if (index > -1) {
			this.activeTab = index;

			document.body.classList.add("is-nav-open");
			this.toggle?.classList.add("is-active");
		}
	}
}
