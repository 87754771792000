/* @license
 * © 2022 Michael Wagner <https://wagnergraphics.ch/>
 * SPDX-License-Identifier: Apache-2.0
 *
 * © 2021 Daniel Aleksandersen <https://www.daniel.priv.no/>
 * SPDX-License-Identifier: Apache-2.0
 *
 * © 2016–2017 The New York Times Company <https://www.nytco.com/>
 * SPDX-License-Identifier: Apache-2.0
 */

const PendingClassName = "textbalancer--pending";
const BalancedClassName = "textbalancer--balanced";

export class TextBalancer {
	balance(elements: string | NodeListOf<HTMLElement>) {
		if (typeof elements === "string") {
			elements = document.querySelectorAll(elements);
		}

		if (window.ResizeObserver) {
			const observer = new ResizeObserver(entries => {
				entries.forEach(entry => {
					let children = entry.target.querySelectorAll<HTMLElement>(`.${PendingClassName},.${BalancedClassName}`);
					for (let element of children) {
						this.balanceElement(element);
					}
				});
			});

			let parents = new Set<HTMLElement>();
			for (let element of elements) {
				if (element.parentElement !== null) {
					element.classList.add(PendingClassName);
					parents.add(element.parentElement);
				}
			}
			for (let uniqueParent of parents) {
				observer.observe(uniqueParent);
			}
		}
		else {
			for (let element of elements) {
				element.classList.add(PendingClassName);
				this.balanceElement(element);
			}
		}
	}

	private balanceElement(element: HTMLElement) {
		element.style.wordBreak = "normal"; // need to make sure we're not hard-wrapping in the middle of a word
		if (this.textElementIsMultipleLines(element)) {
			element.style.maxWidth = "";
			if (element.parentElement == null) {
				return;
			}
			var width = element.parentElement.clientWidth;
			var bottomRange = Math.max(100, width / 2);
			this.squeezeContainer(element, element.clientHeight, bottomRange, width);
		}
		element.classList.replace(PendingClassName, BalancedClassName);
	}

	/**
	 * Make the headline element as narrow as possible while maintaining its current height (number of lines). Binary search.
	 */
	private squeezeContainer(headline: HTMLElement, originalHeight: number, bottomRange: number, topRange: number) {
		if ((bottomRange + 4) >= topRange) {
			headline.style.maxWidth = Math.ceil(topRange) + "px";
			return;
		}
		let mid = (bottomRange + topRange) / 2;
		headline.style.maxWidth = mid + "px";

		if (headline.clientHeight > originalHeight) {
			// we've squoze too far and headline has spilled onto an additional line; recurse on wider range
			this.squeezeContainer(headline, originalHeight, mid, topRange);
		}
		else {
			// headline has not wrapped to another line; keep squeezing!
			this.squeezeContainer(headline, originalHeight, bottomRange, mid);
		}
	}

	private textElementIsMultipleLines(element: HTMLElement) {
		let elementStyles = window.getComputedStyle(element);
		let elementLineHeight = parseInt(elementStyles.lineHeight, 10);
		let elementHeight = parseInt(elementStyles.height, 10);
		return elementLineHeight < elementHeight;
	}
}
