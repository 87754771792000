import { Key } from "ts-key-enum";

export class Modal {
	modal: HTMLElement;
	trigger: HTMLElement;
	isOpen: boolean;

	constructor(modal: HTMLElement, trigger: HTMLElement) {
		this.modal = modal;
		this.trigger = trigger;

		this.modal.querySelector(".modal-close, .modal-close--unstyled")?.addEventListener("click", this.closeBindable);
		this.modal.querySelector(".modal-background")?.addEventListener("click", this.closeBindable);

		this.trigger.addEventListener("click", this.openBindable);
	}

	openBindable = this.open.bind(this);
	open() {
		this.isOpen = true;
		this.modal.classList.add("is-active");
		window.addEventListener("keydown", this.onKeyPressedBindable);
	}

	closeBindable = this.close.bind(this);
	close() {
		this.isOpen = false;
		this.modal.classList.remove("is-active");
		window.removeEventListener("keydown", this.onKeyPressedBindable);
	}

	onKeyPressedBindable = this.onKeyPressed.bind(this);
	onKeyPressed(e: KeyboardEvent) {
		if (e.key == Key.Escape) {
			this.close();
		}
	}
}
