import { FluentBundle, FluentResource } from "@fluent/bundle";
import { createFluentVue } from "fluent-vue";

import deMessages from "../Localization/de-CH.ftl?raw";
import frMessages from "../Localization/fr-CH.ftl?raw";
import enMessages from "../Localization/en-CH.ftl?raw";

const deBundle = new FluentBundle("de");
deBundle.addResource(new FluentResource(deMessages));

const frBundle = new FluentBundle("fr");
frBundle.addResource(new FluentResource(frMessages));

const enBundle = new FluentBundle("en");
enBundle.addResource(new FluentResource(enMessages));

const bundles: { [lang: string]: FluentBundle } = {
	de: deBundle,
	fr: frBundle,
	en: enBundle,
};

export const fluent = createFluentVue({
	bundles: [bundles[document.documentElement.lang]]
});

export const t = fluent.format;
export const ta = fluent.formatAttrs;
