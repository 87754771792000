import { Vue, Component, Prop, Watch, Inject, Ref } from "@wagich/vue-facing-decorator-metadata";
import algoliasearch, { type SearchClient } from "algoliasearch";
import type { SearchOptions, SearchResponse, Hit } from "@algolia/client-search";
import { debounce } from "lodash";

import { render } from "./global-search.html";
import type { SearchResultItem } from "./models";
import { isNullOrEmpty } from "../utils";

interface BInputShim {
	focus: () => void;
}

enum State {
	Initial = "initial",
	Busy = "busy",
	Results = "results",
	NoResults = "no-results",
}


@Component({
	render
})
export class GlobalSearch extends Vue {
	private _client: SearchClient;

	@Prop() apiKey: string;
	@Ref() searchElement: BInputShim;

	isActive: boolean = false;
	isDirty: boolean = false;
	state: State = State.Initial;
	query: string = "";
	queryId: string = "";
	results: Hit<SearchResultItem>[] = [];

	get hasQuery(): boolean {
		return this.query.length > 0;
	}
	get hasResults(): boolean {
		return this.results.length > 0;
	}

	created() {
		this._client = algoliasearch("ZHMZYRPHEG", this.apiKey);
	}

	mounted() {
		document.addEventListener("open-search", () => this.open());
	}

	open() {
		this.isActive = true;
		this.$nextTick(() => this.searchElement.focus());
	}
	close() {
		this.isActive = false;
		this.clear();
	}

	clear() {
		this.query = "";
		this.results = [];
		this.state = State.Initial;
	}

	@Watch("isActive")
	onIsActiveChanged() {
		if (this.isActive === false) {
			this.isDirty = false;
			this.clear();
		}
	}

	@Watch("query")
	onQueryChanged(value: string) {
		if (!isNullOrEmpty(value)) {
			this.state = State.Busy;
			this.searchDebounced(value);
		} else {
			this.clear();
		}
	}

	readonly searchDebounced = debounce(this.search, 350);
	async search(query: string) {
		let response = await this._client.search<SearchResultItem>([{
			indexName: `PROD_documents_${document.documentElement.lang}`,
			query: query,
			params: {
				//clickAnalytics: true,
			}
		}]);
		this.results = (response.results[0] as SearchResponse<SearchResultItem>).hits;
		//this.queryId = response.results[0].queryID!;

		//await new Promise(resolve => setTimeout(resolve, 500));

		this.isDirty = true;

		if (this.hasResults) {
			this.state = State.Results;
		} else {
			this.state = State.NoResults;
		}

		try {
			_paq.push(["trackSiteSearch", query, undefined, this.results.length])
		} catch {}
	}

	getContentSnippet(item: SearchResultItem): string {
		if (item._snippetResult && item._snippetResult.content) {
			return item._snippetResult.content.value;
		} else {
			return item.content;
		}
	}
}
