import bulmaCollapsible from "@creativebulma/bulma-collapsible";
import tippy from "tippy.js";
import { Modal } from "./modal";

export class Page {
	constructor() {
		bulmaCollapsible.attach(".is-collapsible");

		for (let trigger of document.querySelectorAll<HTMLElement>("[aria-haspopup='dialog']")) {
			let modalId = trigger.getAttribute("aria-controls");
			if (modalId == null) {
				continue;
			}
			let modal = document.getElementById(modalId);
			if (modal == null) {
				continue;
			}
			new Modal(modal, trigger);
		}

		for (let button of document.querySelectorAll<HTMLButtonElement>("input[type=button][data-loadingindicator], button[data-loadingindicator]")) {
			button.form?.addEventListener("submit", e => {
				button.classList.add("is-loading");
			});
		}

		let autoscrollTarget = document.querySelector("[data-autoscroll]");
		if (autoscrollTarget != null) {
			window.scrollTo({
				top: autoscrollTarget.getBoundingClientRect().top - (window.innerHeight * 0.1),
			});
		}

		for (let element of document.querySelectorAll("[data-tippy]")) {
			let tooltipContent = element.getAttribute("data-tippy");
			if (tooltipContent == null || tooltipContent.length === 0) {
				continue;
			}
			tippy(element, {
				content: tooltipContent,
				animation: "scale-subtle",
				theme: "bulma",
			});
		}

		if (document.querySelectorAll("[data-fancybox], .is-zoomable").length > 0) {
			import("@fancyapps/ui").then(m => {
				m.Fancybox.bind("[data-fancybox], .is-zoomable");
			});
		}

		document.querySelector(".header-tickets__buy-button")?.addEventListener("click", () => {
			_paq.push(["trackEvent", "Tickets", "Header Ticketkauf"])
		});
	}
}
