<b-modal class="global-search-modal" has-modal-card width="auto" scroll="keep" :trap-focus="true" :can-cancel="['escape', 'outside']" v-model="isActive">
	<div class="modal-card global-search" :class="{ 'has-results': hasResults }">
		<header class="modal-card-head">
			<div class="modal-card-title">
				<b-input class="global-search__input" ref="searchElement" size="is-large" :placeholder="$ta('search-input').placeholder" icon="search" v-model="query" />
			</div>

			<button class="button is-icon is-light modal-close--unstyled" aria-label="close" @click="close">
				<svg-icon name="close" />
			</button>
		</header>
		<section class="modal-card-body" :class="{ 'is-flex-grow-0': !isDirty }">
			<b-loading :is-full-page="false" :active="state === 'busy'" />
			<div class="global-search__results" v-if="state === 'results'">
				<a class="global-search__result" :href="result.url" v-for="result in results">
					<p class="title is-8 mb-0">{{result.title}}</p>
					<p v-html="getContentSnippet(result)" />
				</a>
			</div>
			<div class="global-search__no-results" v-if="state === 'no-results'">
				<p class="title is-5">{{$ta('search-noresults').title}}</p>
			</div>
			<div class="global-search__initial" v-if="state === 'initial'">
				<p class="title is-5"></p>
			</div>
		</section>
	</div>
</b-modal>
