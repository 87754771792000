export class Navbar {
	constructor() {
		for (let item of document.querySelectorAll(".status-item")) {
			let statusButton = item.querySelector(".status-button");

			statusButton?.addEventListener("click", e => {
				let button = e.currentTarget as HTMLElement;
				let activeButton = document.querySelector(".status-button.is-active");

				if (button != activeButton) {
					document.querySelector(".status-item.is-active")?.classList.remove("is-active");
					activeButton?.classList.remove("is-active");
				}

				button.classList.toggle("is-active");
				item.classList.toggle("is-active");
			});
			statusButton?.addEventListener("click", e => {
				try {
					_paq.push(["trackEvent", "Interaktionen", "Status angezeigt", (e.target as HTMLElement).dataset["name"]]);
				} catch { }
			}, { once: true });
		}

		document.querySelector(`[aria-controls="mobileStatusModal"]`)?.addEventListener("click", () => {
			try {
				_paq.push(["trackEvent", "Interaktionen", "Status angezeigt"]);
			} catch { }
		}, { once: true });

		let livecamButton = document.getElementById("livecamButton");
		if (livecamButton != null) {
			livecamButton.setAttribute("data-width", (window.innerWidth * 0.8).toString());
			livecamButton.addEventListener("click", () => {
				try {
					_paq.push(["trackEvent", "Interaktionen", "Livecam angezeigt"]);
				} catch { }
			});
		}

		let searchButton = document.getElementById("searchButton");
		searchButton?.addEventListener("click", e => {
			document.dispatchEvent(new CustomEvent("open-search"));
		});
	}
}
