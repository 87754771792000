import { Vue, Component, Prop, Ref, Watch } from "@wagich/vue-facing-decorator-metadata";
import tippy, { type Instance as TippyInstance, type Placement } from "tippy.js";

import { render } from "./tippy-tooltip.html";


@Component({
	render
})
export class TippyTooltip extends Vue {
	private tippy: TippyInstance;
	@Ref() element: HTMLElement;

	@Prop() label: string;
	@Prop({ default: "top" }) placement: Placement;

	mounted() {
		this.tippy = tippy(this.element, {
			content: this.label,
			placement: this.placement,
			animation: "scale-subtle",
			theme: "bulma",
		});
	}

	@Watch("label")
	onLabelChanged() {
		this.tippy?.setContent(this.label);
	}
}
