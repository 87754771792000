<div class="navigation-collapsible is-h-100" v-if="!isTablet">
	<b-collapse animation="slide" :key="index" :modelValue="activePanel == index" @open="activePanel = index" :aria-id="'mainnavcollapsible-' + index" v-for="(item, index) of tabs">
		<template #trigger="props">
			<div class="navigation-collapsible__title" role="button" :aria-controls="'mainnavcollapsible-' + index" :aria-expanded="props.open">
				{{ item.title }}
				<b-icon size="is-small" :icon="props.open ? 'chevron-down' : 'chevron-right'" />
			</div>
		</template>
		<div class="navigation-collapsible__content">
			<slot :name="item.id"></slot>
		</div>
	</b-collapse>
</div>

<b-tabs class="navigation-tabs" size="is-large" v-model="activeTab" v-if="isTablet">
	<b-tab-item :label="item.title" v-for="(item, index) of tabs">
		<slot :name="item.id"></slot>
	</b-tab-item>
</b-tabs>
