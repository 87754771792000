import "../Styles/index.scss";

import "reflect-metadata";

import { defineCustomElement as defineCustomElementCore, type App, type Component } from "vue";
import { ConfigProgrammatic, Field, Input, Checkbox, Radio, Button, Select, Switch, Loading, Notification, Modal, Snackbar, Datepicker, Numberinput, Dropdown, Tabs, Tooltip, Toast, Steps, Collapse, Icon } from "buefy";
import PortalVue from "portal-vue";
import FontFaceObserver from "fontfaceobserver";
import { initializeEmbeds } from "@wagich/bluc-embed";
import { initializeMaps } from "@wagich/bluc-map";

import { fluent } from "./localization";
import { TextBalancer } from "./text-balancer";
import { formatCurrency, getLocaleValue } from "./utils";
import { SvgIcon } from "~/components/svg-icon";
import { TippyTooltip } from "./components/tippy-tooltip";
import { MainNavigation } from "./navigation/main-navigation";
import { GlobalSearch } from "./search/global-search";
import { Navigation } from "./navigation";
import { Navbar } from "./navbar";
import { Offer } from "./offer";
import { Page } from "./page";

(async function () {
	let balancer = new TextBalancer();
	await new FontFaceObserver("itc-officina-sans-pro").load();
	balancer.balance(".is-text-balanced");
})();

new Navigation();
new Navbar();
new Offer();
new Page();

initializeEmbeds();
initializeMaps();

ConfigProgrammatic.setOptions({
	defaultUseHtml5Validation: false,
	defaultIconPack: "icon",
	defaultIconComponent: "svg-icon",
});

function defineAndConfigureCustomElement(component: any) {
	return defineCustomElementCore(component.__vccOpts, {
		shadowRoot: false,
		configureApp(app) {
			app.component("svg-icon", SvgIcon);
			app.component("tippy-tooltip", TippyTooltip);

			app.use(Field);
			app.use(Collapse);
			app.use(Input);
			app.use(Checkbox);
			app.use(Radio);
			app.use(Button);
			app.use(Select);
			app.use(Switch)
			app.use(Loading);
			app.use(Notification);
			app.use(Modal);
			app.use(Snackbar);
			app.use(Datepicker);
			app.use(Numberinput);
			app.use(Dropdown);
			app.use(Tooltip);
			app.use(Toast);
			app.use(Tabs);
			app.use(Steps);
			app.use(Icon);

			app.use(PortalVue);
			app.use(fluent);

			app.config.globalProperties.$formatters = {
				currency: formatCurrency,
				localized: getLocaleValue,
			};
		},
	})
}

customElements.define("main-navigation", defineAndConfigureCustomElement(MainNavigation));
customElements.define("global-search", defineAndConfigureCustomElement(GlobalSearch));

if (document.querySelector("booking-button") != null) {
	import("./reservation/booking-button").then(m => {
		customElements.define("booking-button", defineAndConfigureCustomElement(m.BookingButton)); //jsonprops
	});
}

if (document.querySelector("newsletter-signup") != null) {
	import("./newsletter/newsletter-signup").then(m => {
		customElements.define("newsletter-signup", defineAndConfigureCustomElement(m.NewsletterSignup));
	});
}

if (document.querySelector("contact-form") != null) {
	import("./contact/contact-form").then(m => {
		customElements.define("contact-form", defineAndConfigureCustomElement(m.ContactForm));
	});
}
